import beakers from "./images/beakers_sm.jpg";

function Discover(props:any){
    return(
      <section className="centered">
        <img className="backgroundImage" alt="" src={ beakers } />
        <div className="overBackground">
          <h2 className="h1 padtop_bigger">
            <a className="linedHover" href="/process">Discover</a>, <a className="linedHover" href="/process">Design</a>, <a className="linedHover" href="/process">Deploy</a>
          </h2>
          <h4 className="discoverSubhead padtop_big">
            See how we mix art into the science
            of every data consulting solution.
          </h4>
          <a href="/the-process" className="button yellow">The Process</a>
        </div>
      </section>
    )
  }

  export default Discover;