

export default function CareersPromo(){
  return(
    <section className="floodBlack">
        <h4 className="tightbottom">
          Interested in working with Acorn? Our team is growing.
        </h4>
        <div className="half">
            <p className="caption">If you see a fit with our core values and you’re excited to help us shape a fresh approach to company culture, explore our latest opportunities.</p>
            <a href="/careers" className="button yellow">CAREERS</a>
        </div>
      </section>
  )
}