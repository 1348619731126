
interface teaser{
  title: string,
  body: string,
  link: string,
  linktitle: string,
  target: string
}

function DynamicThreeUp(props:any){
    
    let teasers:teaser[] = [
        { title: "Under our hood", body: "We’re a company on a mission that goes beyond being world-class data science ninjas. See why our impact on society and our fellow humans really fires us up.", link: "/about-us", linktitle: "ABOUT", target: "_self" },
        { title: "Our humans", body: "We’re science nerds, loud and proud, but don’t really fit the darkroom data cruncher mold. We’re really into people and pets. Get to know us!", link: "/team", linktitle: "TEAM", target: "_self" },
        { title: "The Real Deal", body: "Time to get real? Go ahead, dig into the deets of our recent client case studies to see how our crack team delivered tangible business impact.", link: "/the-proof", linktitle: "THE PROOF", target: "_self" },
        { title: "Work with us", body: "Whether you’re a data superhero, super analytical, or simply super natural, you might be the right fit for helping Acorn create juicy stuff.", link: "/careers", linktitle: "CAREERS", target: "_self" },
        { title: "How it goes down", body: "Our proprietary approach gets all up in your business to diagnose your data issues. We really want to get you, then get into the science. Precisely. Human. ", link: "/the-process", linktitle: "THE PROCESS", target: "_self" },
        { title: "Connect with us", body: "See how Data People flow, day ta day! Check us out on LinkedIn and share your big data comments and questions. Nerd alert: we love puzzles.", link: "http://linkedin.com/company/acorn-analytics-inc.", linktitle: "FOLLOW US", target: "_blank" },
        { title: "Boogie Down", body: "Get down ‘n’ nerdy with us. Tune in on Spotify to hear our latest playlists keeping us inspired and boogieing down.", link: "https://open.spotify.com/user/zdjhupypv5lvhi39eu76hfvlv?si=c879b236b655417a", linktitle: "LISTEN", target: "_blank" },
        { title: "Get in touch", body: "The best way to check us out is to get up close and personal with one of our data consultants. Let’s talk! See your data alligatah.", link: "/contact-us", linktitle: "CONTACT US", target: "_self" },
        { title: "The bigger picture", body: "Dive into the hot data topics keeping our scientists up at night. From privacy and ethics issues to new trends, you’ll sleep easier with this intel even if they won’t.", link: "/blog", linktitle: "BLOG", target: "_self" }
    ];

    let orderedSubsetOfTeasers = [];
    for(let x=0; x<props.set.length; x++){
      for(let y=0; y<teasers.length;y++){
        if(teasers[y].linktitle === props.set[x]){
          orderedSubsetOfTeasers.push(teasers[y]);
        }
      }
    }

    let filteredTeasers = teasers.filter(teaser => props.set.includes(teaser.linktitle));

    return(
      <section className="floodRed tighterbottom">
        <div className="threeColumns ruled">
            { orderedSubsetOfTeasers.map((teaser:any, i:number) => 
            <div className="column" key={ i }>
                <div className="title">{ teaser.title }</div>
                <div className="body">{ teaser.body }</div>
                <div className="action">
                <a href={ teaser.link } target={ teaser.target } className="button red">{ teaser.linktitle } </a>
                </div>
            </div>
            )}
        </div>
      </section>
    )
  }
  
  export default DynamicThreeUp;
  