import { useState } from "react";
import radio from "./images/radio.jpg";
import DynamicThreeUp from "./dynamicthreeup";
import BookAConsultation from "./bookconsultation";
import {Helmet} from "react-helmet";


function ContactForm(props:any){
  let [firstname, setFirstname] = useState("");
  let [lastname, setLastname] = useState("");
  let [company, setCompany] = useState("");
  let [email, setEmail] = useState("");
  let [message, setMessage] = useState("");
  let [phone, setPhone] = useState("");
  let [emailError, setEmailError] = useState(true);
  let [firstnameError, setFirstnameError] = useState(true);
  let [lastnameError, setLastnameError] = useState(true);
  let [formsent, setFormsent] = useState(false);
  

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    let hasAnError = false;
    
    // check email
    if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)){
      setEmailError(false);
      document.getElementById("email")?.classList.remove("error");
    }
    else{
      hasAnError = true;
      setEmailError(true);
      document.getElementById("email")?.classList.add("error");
    }
    
    if(firstname !== ""){
      setFirstnameError(false);
      document.getElementById("firstname")?.classList.remove("error");
    }
    else{
      hasAnError = true;
      setFirstnameError(true);
      document.getElementById("firstname")?.classList.add("error");
    }

    if(lastname !== ""){
      setLastnameError(false);
      document.getElementById("lastname")?.classList.remove("error");
    }
    else{
      hasAnError = true;
      setLastnameError(true);
      document.getElementById("lastname")?.classList.add("error");
    }

    if(hasAnError){
        // we got an error, we gotta stop
        console.warn("ERROR");
        setFormsent(false);
    }
    else{
        console.info("NO ERROR");
        // let's light this candle
        let newformdata = {
            email: email,
            firstname: firstname,
            lastname: lastname,
            company: company,
            phone: phone,
            message: message
        }
        setFormsent(true);
        window.scrollTo(0,0);
        console.info(newformdata);
        let s = document.getElementById("formsubmit");
        s?.classList.add("disabled");
    }

    return true;

    const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
        fields: [
        { "objectTypeId": "0-1",
        "name": "email",
        "value": email
        }
        ],
        "legalConsentOptions": {
        "consent": { // Include this object when GDPR options are enabled
            "consentToProcess": true,
            "text": "I agree to allow Acorn Analytics to store and process my personal data.",
            "communications": [
            {
                "value": true,
                "subscriptionTypeId": 999,
                "text": "I agree to receive marketing communications from Acorn Analytics."
            }
            ]
        }
        }
    })
    };
    fetch('https://reqres.in/api/posts', requestOptions)
        .then(response => response.json())
        .then(data => setEmailError(true));
    
  };


    if(formsent){
        return(
            <section className="contactformWrapper" style={ { backgroundImage: `url(${radio})` }}>
                <div>
                    <h1 className="tightbottom">We’ll be in touch!</h1>
                    <h6>Thanks, { firstname }, we’re excited to get to know you.<br />If you need anything in the meantime, please call us directly at <a className="red" href="tel:9253996887">(925) 388-6887</a>.</h6>
                </div>
            </section>
        )
    }
    
    return(
        <section className="contactformWrapper" style={ { backgroundImage: `url(${radio})` }}>
             <div>
                <h1 className="tightbottom">Get in touch</h1>
                <h6>Tell us how our team of experts can help crack your data challenges.</h6>
            </div>
            <form className="contactform" onSubmit={onSubmit}>
                <div className="sidebyside">
                    <div className="inputWrapper">
                        <label htmlFor="firstname" className="red">First name*</label>
                        <input name="firstname" id="firstname" placeholder="* Required" onChange={e => setFirstname(e.target.value)} value={firstname} />
                    </div>
                    <div className="inputWrapper column">
                        <label htmlFor="lastname" className="red">Last name*</label>
                        <input name="lastname" id="lastname" placeholder="* Required" onChange={e => setLastname(e.target.value)} value={lastname} />
                    </div>
                </div>
                <div className="inputWrapper">
                    <label htmlFor="company" className="red">Your company</label>
                    <input name="company" placeholder="" id="company" onChange={e => setCompany(e.target.value)} value={company} />
                </div>
                <div className="inputWrapper">
                    <label htmlFor="email" className="red">Email*</label>
                    <input name="email" id="email" type="email" placeholder="* Required" onChange={e => setEmail(e.target.value)} value={email} />
                </div>
                <div className="inputWrapper">
                    <label htmlFor="message" className="red">Message</label>
                    <textarea name="message" id="message" placeholder="Not required, but we‘d love to hear from you." onChange={e => setMessage(e.target.value)} value={message} />
                </div>
                <div className="inputWrapper">
                    <label htmlFor="phone" className="red">Phone number</label>
                    <input name="phone" id="phone" type="tel" placeholder="" onChange={e => setPhone(e.target.value)} value={phone} />
                </div>
                <div className="inputWrapper">
                    <button className="yellow" id="formsubmit">Send It</button>
                </div>
            </form>
        </section>
    )
}


function ContactThreeUp(props:any){
    return(
      <section className="floodRed">
        <div className="threeColumns lined">
          <div className="column">
            <div className="title">Our humans</div>
            <div className="body">
              We’re science nerds, loud and proud, but don’t really fit the dark room data cruncher mold. We’re really into people and pets. Get to know us!
            </div>
            <div className="action">
              <a href="/" className="button red">Team</a>
            </div>
          </div>
          <div className="column">
            <div className="title">Work with us</div>
            <div className="body">
              Whether you’re a data superhero, super analytical, or simply super natural, you might be the right fit for helping us create juicy stuff.
            </div>
            <div className="action">
              <a href="/careers" className="button red">Careers</a>
            </div>
          </div>
          <div className="column">
            <div className="title">Boogie Down</div>
            <div className="body">
              Get down 'n' nerdy with us. Tune in on Spotify to hear our latest playlists keeping us inspired and boogieing down.
            </div>
            <div className="action">
              <a href="/" className="button red">Listen</a>
            </div>
          </div>
        </div>
      </section>
    )
  }

  



function ContactUs(props: any) {
  return(
    <>
      <Helmet>
        <title>Acorn Analytics // Contact Us</title>
        <meta name="description" content="Are data hassles getting in the way of your business potential? Talk to our data science gurus to see how our full stack capabilities can help. Check us out!" />
      </Helmet>
      <ContactForm />
      <BookAConsultation />
      <DynamicThreeUp set={ ["THE PROOF", "TEAM", "ABOUT"] } />
    </>
  )
}


export default ContactUs;
