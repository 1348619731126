import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import radio from "./images/radio.jpg";
import caseimage_predictchurn from "./images/caseimage_predictchurn.jpg";
import caseimage_producttesting from "./images/caseimage_producttesting.jpg";
import caseimage_machinelearning from "./images/caseimage_machinelearning.jpg";
import caseimage_cloudmigration from "./images/caseimage_cloudmigration.jpg";

import DynamicThreeUp from "./dynamicthreeup";
import BookAConsultation from "./bookconsultation";
import {Helmet} from "react-helmet";


function Case(props:any){
    type CaseParams = {
        casename: string;
    };
    const { casename } = useParams<CaseParams>();
    if(casename.toLowerCase() === "product-testing"){
        return(
            <CaseProductTesting />
        )
    }
    else if(casename.toLowerCase() === "predict-churn"){
        return(
            <CasePredictChurn />
        )
    }
    else if(casename.toLowerCase() === "sales-forecasting"){
        return(
            <CaseSalesForecasting />
        )
    }
    else if(casename.toLowerCase() === "cloud-migration"){
        return(
            <CaseCloudMigration />
        )
    }
    
    return(
        <section className="floodYellow">
            <h1>Case Not Found!</h1>
            <h5>Sorry — something’s wrong. We’ll be getting on that right away…</h5>
        </section>
    )
}


function CaseProductTesting(){
    useEffect(() => {
        // Update the margin top of the discover field to match the middle of the pullout
        let pulloutHeight = document.getElementById("firstpullout")?.offsetHeight;
        if(pulloutHeight){
            if(document.getElementById("caseDiscover") !== null){
                let cd = document.getElementById("caseDiscover");
                if(cd){
                    cd.style.marginTop = (pulloutHeight/2 - 8) + "px";
                }
            } 
        }

        let spo = document.getElementById("secondpullout");
        if(spo){
            spo.style.bottom = (-1 * (spo.offsetHeight / 2)) + "px"; 
        }
    });
    return(
        <>
            <section className="floodYellow padtop_jumbo caseDetailHeader">
                <a href="/the-proof" className="yellow button">&lt; All Case Studies</a>
                <h1 className="casetitle">Product testing platform reimagines customer support</h1>
                <h6><span className="title bordered">Industry</span>Technology<span className="title bordered marginleft">Function</span>Customer Success</h6>
                <div className="caseimage" style={ { backgroundImage: `url(${caseimage_producttesting})` }}></div>
            </section>
            <section className="caseBody">
                <div className="padtop_jumbo ddd">
                    <div className="title twoxleading red">Discover</div>
                    <div className="bodyDetail twoxleading red">When a breast pump maker lost key members of its team just before going to market, our data scientists stepped in to rapidly create a product testing platform.</div>
                    <div className="title twoxleading red">Design</div>
                    <div className="bodyDetail twoxleading red">We created a system to identify user product issues through the product’s mobile app and streamlined the client’s data infrastructure.</div>
                    <div className="title twoxleading red">Deploy</div>
                    <div className="bodyDetail twoxleading red">Our app solution accurately identified user errors and automated troubleshooting responses, which reduced demand for customer support intervention.</div>
                </div>
                <div className="pullout floodYellow" id="firstpullout">
                    <h3>No one said pumping breast milk was easy. We gave the client more data visibility so they could improve the customer experience.</h3>
                </div>
                <div className="caseDiscover" id="caseDiscover">
                    <div className="caseContent">
                        <div className="title red">Part 1</div>
                        <h3 className="lined">Discover</h3>
                        <p className="red twoxleading padtop_medium">During the product testing phase, our goal was to develop a system for identifying equipment errors reported by the mobile application and automate error responses to improve the user experience. Partnering with the company’s customer success team, our scientists documented common errors, such as incorrect flange sizing, as well as the relevant solutions to each issue. Additionally, <span className="highlight">we uncovered flaws in the existing data structure that caused inaccurate error alerts and required human intervention</span> through customer service.</p>
                    </div>
                </div>
                <div className="caseDesign">
                    <div className="caseContent">
                        <div className="title red">Part 2</div>
                        <h3 className="lined">Design</h3>
                        <p className="red twoxleading padtop_medium">Our team worked hand-in-hand with the customer success team to create a process for identifying and automating solutions to the known user issue alerts. <span className="highlight">We reviewed and refined the system to reliably assign the correct solutions</span> and minimize the amount of customer support intervention needed. Finally, we reviewed the parameters for the current error alert process and provided a solution to inaccuracies.</p>
                    </div>
                    <div className="secondpullout floodYellow" id="secondpullout">
                        <h3>We polished the mobile app’s error alert system so it would lead pump users to success.</h3>
                    </div>
                </div>
                <div className="caseDeploy">
                    <div className="caseContent">
                        <div className="title red">Part 3</div>
                        <h3 className="lined">Deploy</h3>
                        <p className="red twoxleading padtop_medium">After implementing the refinements identified through testing, our data scientists applied the same parameters to the final product environment. <span className="highlight">This resulted in a seamless experience for end-users, as they now received alerts with tips to resolve reported device errors/issues through the app in real-time.</span> If a replacement part was needed, the system could identify it and automatically ship the part. With other product issues, the app routed the user to the customer success team. This comprehensive workflow resulted in a streamlined internal process and an improved user experience.</p>
                    </div>
                </div>
            </section>
        </>
    )
  }

  function CasePredictChurn(){
    useEffect(() => {
        // Update the margin top of the discover field to match the middle of the pullout
        let pulloutHeight = document.getElementById("firstpullout")?.offsetHeight;
        if(pulloutHeight){
            if(document.getElementById("caseDiscover") !== null){
                let cd = document.getElementById("caseDiscover");
                if(cd){
                    cd.style.marginTop = (pulloutHeight/2 - 8) + "px";
                }
            } 
        }

        let spo = document.getElementById("secondpullout");
        if(spo){
            spo.style.bottom = (-1 * (spo.offsetHeight / 2)) + "px"; 
        }
    });
    return(
        <>
            <section className="floodYellow padtop_jumbo caseDetailHeader">
                <a href="/the-proof" className="yellow button">&lt; All Case Studies</a>
                <h1 className="casetitle">Designing a model to predict churn</h1>
                <h6><span className="title bordered">Industry</span>Technology<span className="title bordered marginleft">Function</span>Customer Success</h6>
                <div className="caseimage" style={ { backgroundImage: `url(${caseimage_predictchurn})` }}></div>
            </section>
            <section className="caseBody">
                <div className="padtop_jumbo ddd">
                    <div className="title twoxleading red">Discover</div>
                    <div className="bodyDetail twoxleading red">Our client’s forecasts on customer attrition weren’t reliable, so they wanted us to create a model that could accurately predict churn.</div>
                    <div className="title twoxleading red">Design</div>
                    <div className="bodyDetail twoxleading red">We designed a predictive model that included new approaches to tracking and benchmarking customer engagement.</div>
                    <div className="title twoxleading red">Deploy</div>
                    <div className="bodyDetail twoxleading red">Our model allowed the client to reliably predict a customer who will churn 85-90% of the time.</div>
                </div>
                <div className="pullout floodYellow" id="firstpullout">
                    <h3>Customer attrition is every client’s worst nightmare, but predicting which customers will churn is tougher than it seems.</h3>
                </div>
                <div className="caseDiscover" id="caseDiscover">
                    <div className="caseContent">
                        <div className="title red">Part 1</div>
                        <h3 className="lined">Discover</h3>
                        <p className="red twoxleading padtop_medium">Our team met with the key stakeholders to review any gaps and areas of improvement for the challenges they faced. The client indicated that <span className="highlight">they didn’t have the model specifically to assess churn, and as such, their predictions were unreliable.</span> Other issues included limited available data, internal process gaps, and inconsistent documentation.</p>
                    </div>
                </div>
                <div className="caseDesign">
                    <div className="caseContent">
                        <div className="title red">Part 2</div>
                        <h3 className="lined">Design</h3>
                        <p className="red twoxleading padtop_medium">The data gurus were able to overcome all gaps and deliver <span className="highlight">a predictive model that included new approaches to tracking customer engagement and developing benchmarks.</span> The key principles of the model we developed proved to be counterintuitive to the client’s assumptions. Active and engaged customers were the least likely to churn, while customer inactivity was a key indicator of potential for churn.</p>
                    </div>
                    <div className="secondpullout floodYellow" id="secondpullout">
                        <h3>Our solution was to design a predictive model that could identify customer churn risk with 85-90 percent accuracy.</h3>
                    </div>
                </div>
                <div className="caseDeploy">
                    <div className="caseContent">
                        <div className="title red">Part 3</div>
                        <h3 className="lined">Deploy</h3>
                        <p className="red twoxleading padtop_medium">After implementing our predictive model, <span className="highlight">the client could correctly identify a customer who will reliably churn 85-90% of the time.</span> Additionally, our data solution created an improved assessment of their customers’ engagement and a data-driven approach to benchmarking.</p>
                    </div>
                </div>
            </section>
        </>
    )
  }

  function CaseSalesForecasting(){
    useEffect(() => {
        // Update the margin top of the discover field to match the middle of the pullout
        let pulloutHeight = document.getElementById("firstpullout")?.offsetHeight;
        if(pulloutHeight){
            if(document.getElementById("caseDiscover") !== null){
                let cd = document.getElementById("caseDiscover");
                if(cd){
                    cd.style.marginTop = (pulloutHeight/2 - 8) + "px";
                }
            } 
        }

        let spo = document.getElementById("secondpullout");
        if(spo){
            spo.style.bottom = (-1 * (spo.offsetHeight / 2)) + "px"; 
        }
    });
    return(
        <>
            <section className="floodYellow padtop_jumbo caseDetailHeader">
                <a href="/the-proof" className="yellow button">&lt; All Case Studies</a>
                <h1 className="casetitle">Is sales forecasting machine learning-ready...or not?</h1>
                <h6><span className="title bordered">Industry</span>Technology<span className="title bordered marginleft">Function</span>Sales</h6>
                <div className="caseimage" style={ { backgroundImage: `url(${caseimage_machinelearning})` }}></div>
            </section>
            <section className="caseBody">
                <div className="padtop_jumbo ddd">
                    <div className="title twoxleading red">Discover</div>
                    <div className="bodyDetail twoxleading red">The client wanted a more accurate sales forecasting model using Artificial Intelligence since their current approach was unreliable.</div>
                    <div className="title twoxleading red">Design</div>
                    <div className="bodyDetail twoxleading red">Our analytics gurus used a probability statistics model to aggregate the historical sales data and create a roadmap for a predictive model with machine learning.</div>
                    <div className="title twoxleading red">Deploy</div>
                    <div className="bodyDetail twoxleading red">We ultimately recommended waiting to deploy a predictive model since there wasn’t enough historical data to inform a reliable tool.</div>
                </div>
                <div className="pullout floodYellow" id="firstpullout">
                    <h3>Machine learning sounds pretty sexy, but its efficacy depends on how much historical data is available.</h3>
                </div>
                <div className="caseDiscover" id="caseDiscover">
                    <div className="caseContent">
                        <div className="title red">Part 1</div>
                        <h3 className="lined">Discover</h3>
                        <p className="red twoxleading padtop_medium">The client’s Sales team provided our team a breakdown of key processes and metrics that they wanted to automate via machine learning. <span className="highlight">The current system was unreliable and the team sought to create a predictive model for future sales closure rates.</span> We analyzed all the client’s historical sales data to recommend an approach.</p>
                    </div>
                </div>
                <div className="caseDesign">
                    <div className="caseContent">
                        <div className="title red">Part 2</div>
                        <h3 className="lined">Design</h3>
                        <p className="red twoxleading padtop_medium">Using a probability statistical model known as Bayesian inference (similar to what’s used to program autonomous cars!), <span className="highlight">our data scientists aggregated the historical data to create a roadmap for a predictive model using machine learning.</span> This included an analysis of critical factors throughout the sales process, as well as how to implement the model in the client’s proprietary CRM (Customer Relationship Management) tool.</p>
                    </div>
                    <div className="secondpullout floodYellow" id="secondpullout">
                        <h3>Our data scientists stepped in to do what they do best: clean up the data until it sparkles.</h3>
                    </div>
                </div>
                <div className="caseDeploy">
                    <div className="caseContent">
                        <div className="title red">Part 3</div>
                        <h3 className="lined">Deploy</h3>
                        <p className="red twoxleading padtop_medium"><span className="highlight">Our experts ultimately recommended that the client delay deploying a predictive model because the Sales team did not have enough historical data to inform a reliable tool.</span> We helped the team recognize the value in waiting, while also ensuring the model they funded was flexible enough to accommodate future customers. In the meantime, the client could focus on other parts of the business to grow its customer base and reach out to us in the future to proceed.</p>
                    </div>
                </div>
            </section>
        </>
    )
  }

  function CaseCloudMigration(){
    useEffect(() => {
        // Update the margin top of the discover field to match the middle of the pullout
        let pulloutHeight = document.getElementById("firstpullout")?.offsetHeight;
        if(pulloutHeight){
            if(document.getElementById("caseDiscover") !== null){
                let cd = document.getElementById("caseDiscover");
                if(cd){
                    cd.style.marginTop = (pulloutHeight/2 - 8) + "px";
                }
            } 
        }
        let spo = document.getElementById("secondpullout");
        if(spo){
            spo.style.bottom = (-1 * (spo.offsetHeight / 2)) + "px"; 
        }
    });

    return(
        <>
            <section className="floodYellow padtop_jumbo caseDetailHeader">
                <a href="/the-proof" className="yellow button">&lt; All Case Studies</a>
                <h1 className="casetitle">Turning cloud migration pains into customer gains</h1>
                <h6><span className="title bordered">Industry</span>Technology<span className="title bordered marginleft">Function</span>Customer Success</h6>
                <div className="caseimage" style={ { backgroundImage: `url(${caseimage_cloudmigration})` }}></div>
            </section>
            <section className="caseBody">
                <div className="padtop_jumbo ddd">
                    <div className="title twoxleading red">Discover</div>
                    <div className="bodyDetail twoxleading red">When a client struggled to manage and resource cloud migrations, we dove into the operations and analytics of the internal and customer-facing processes involved.</div>
                    <div className="title twoxleading red">Design</div>
                    <div className="bodyDetail twoxleading red">Our crack team developed dashboards to monitor the migration process as well as data-based milestones to track progress and manage client communications.</div>
                    <div className="title twoxleading red">Deploy</div>
                    <div className="bodyDetail twoxleading red">Our streamlined solution enabled the client to flex operations as needed, align cross-functional teams throughout a migration, and mitigate the risk of customer churn.</div>
                </div>
                <div className="pullout floodYellow" id="firstpullout">
                    <h3>We had a supply chain issue on our hands: employees were all tracking different data points, with no singular source of truth.</h3>
                </div>
                <div className="caseDiscover" id="caseDiscover">
                    <div className="caseContent">
                        <div className="title red">Part 1</div>
                        <h3 className="lined">Discover</h3>
                        <p className="red twoxleading padtop_medium">Our scientists met with stakeholders to assess the main challenges to be addressed. Access to the timing, magnitude, and complexity of upcoming migrations was crucial to the team’s success. <span className="highlight">We found that a lack of visibility into migration details made it difficult to predict the internal personnel needed to engage stakeholders before issues could negatively affect clients.</span> In addition, the available data didn’t include vital metrics baselines, which meant teams couldn’t be held accountable for critical milestones and breakdowns in the process weren’t easy to identify.</p>
                    </div>
                </div>
                <div className="caseDesign">
                    <div className="caseContent">
                        <div className="title red">Part 2</div>
                        <h3 className="lined">Design</h3>
                        <p className="red twoxleading padtop_medium">Our team worked with internal stakeholders to develop a migration metrics tracking system to provide visibility into upcoming, in-progress, and completed migrations. <span className="highlight">We designed cross-functional dashboards to assist in the various aspects of the migration journey</span> within the technology platform specified by the client. We added milestones to the migration scorecard, allowing leaders to track progress and inform customers appropriately. Training materials and documentation to support the rollout and ongoing maintenance were also developed.</p>
                    </div>
                    <div className="secondpullout floodYellow" id="secondpullout">
                        <h3>We needed to create shared metrics baselines to improve customer migration success rates and hold internal teams accountable.</h3>
                    </div>
                </div>
                <div className="caseDeploy">
                    <div className="caseContent">
                        <div className="title red">Part 3</div>
                        <h3 className="lined">Deploy</h3>
                        <p className="red twoxleading padtop_medium">With our solution, the client could flex resources according to needs, proactively manage risk, and measure process compliance and performance. This not only led to internal accountability, but also informed critical operations improvements. By streamlining the entire process, <span className="highlight">the leadership team aligned cross-functional teams and ensured a smoother experience for the client throughout the migration.</span></p>                    </div>
                </div>
            </section>
        </>
    )
  }


function CaseDetail(props: any) {
  return(
    <>
      <Helmet>
        <title>Acorn Analytics // Case Study</title>
        <meta name="description" content="If you’re an engineer or project leader on a mission to help people and the planet, Acorn may be the last job you’ll ever have. Not your stock data consultancy." />
      </Helmet>
      <Case />
      <BookAConsultation />
      <DynamicThreeUp set={ ["THE PROCESS", "CONTACT US", "BLOG"] } />
    </>
  )
}


export default CaseDetail;
