


function PrivacyHeader(props:any){
    return(
        <section className="centered floodRed">
            <h1>Privacy Policy</h1>
            <div className="title">Effective:<br />September 8, 2018</div>
        </section>
    )
}


function PrivacyDetails(props:any){
    return(
        <section className="longform">
            <p className="padtop_medium">This page informs you of our policies regarding the collection, use and disclosure of Personal Information when you use our Service.</p>
            <p>We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Use, accessible at <a href="/terms-of-use">www.acornanalytics.com/terms-of-use</a></p>
            
            <h4>Who we are</h4>
            <p>Acorn Analytics Inc. provides consulting and implementation services related to data science, analytics, artificial intelligence and data engineering. We assist other companies with their data needs, including providing information on our website. Our website address is: http://acornanalytics.com.</p>
            
            <h4>Information Collection and Use</h4>
            <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. The amount of information you provide is completely voluntary; however, providing less information might limit the your ability to access all of the site’s features.</p>
            <p>Personally identifiable information (“Personal Information”) may include, but is not limited to:</p>
            <ul>
                <li>Name</li>
                <li>Email Address</li>
                <li>IP Address</li>
                <li>Telephone number</li>
            </ul>
            
            <p>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</p>
            
            <h4>Comments</h4>
            <p>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.</p>
            <p>If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.</p>
            <p>An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: <a href="https://automattic.com/privacy/" target="_blank">https://automattic.com/privacy/</a>. After approval of your comment, your profile picture is visible to the public in the context of your comment.</p>
            
            <h4>Media</h4>
            <p>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.</p>
            
            <h4>Log Data</h4>
            <p>We collect information that your browser sends whenever you visit our Service (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
            
            <h4>Cookies</h4>
            <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive.</p>
            <p>We use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
            <p>If you leave a comment on our site you may opt-in to save your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.</p>
            <p>If you have an account and you log in to this site, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>
            <p>When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</p>
            <p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</p>
            
            <h4>Embedded content from other websites</h4>
            <p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
            <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>
            
            <h4>Service Providers</h4>
            <p>We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
            <p>These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
            
            <h4>Security</h4>
            <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
            
            <h4>What rights you have over your data</h4>
            <p>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>
            
            <h4>Links To Other Sites</h4>
            <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
            <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
            
            <h4>Children’s Privacy</h4>
            <p>Our Service does not address anyone under the age of 13 (“Children”).</p>
            <p>We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with Personal Information, please contact us. If we discover that a child under 13 has provided us with Personal Information, we will delete such information from our servers immediately.</p>
            
            <h4>Compliance With Laws</h4>
            <p>We will disclose your Personal Information where required to do so by law or subpoena.</p>
            
            <h4>Changes To This Privacy Policy</h4>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            
            <h4>Contact Us</h4>
            <p>If you have any questions about this Privacy Policy, please contact us.</p>
            <p>You can reach us through the contact links available on this website, or by contacting our Data Compliance Officer:</p>
            <p>Acorn Analytics Inc.<br />ATTN: Data Compliance Officer<br />1261 Locust Street<br />Suite 3<br />Walnut Creek, CA 94596</p>
        </section>
    )
}
  



function Privacy(props: any) {
  return(
    <>
      <PrivacyHeader />
      <PrivacyDetails />
    </>
  )
}


export default Privacy;
