import { useState } from "react";
import acorn from './images/singleAcorn.jpg'
import linkedin from './images/linkedin.svg'
import spotify from './images/spotify.svg'
import oneftp from './images/oneftp-logo.svg'

function EmailForm(){
  let [email, setEmail] = useState("");
  let [error, setError] = useState("");
  let [response, setResponse] = useState("");

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)){
      setError("");
      

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          fields: [
            { "objectTypeId": "0-1",
            "name": "email",
            "value": email
            }
          ],
          "legalConsentOptions": {
            "consent": { // Include this object when GDPR options are enabled
              "consentToProcess": true,
              "text": "I agree to allow Acorn Analytics to store and process my personal data.",
              "communications": [
                {
                  "value": true,
                  "subscriptionTypeId": 999,
                  "text": "I agree to receive marketing communications from Acorn Analytics."
                }
              ]
            }
          }
        })
      };
      //https://reqres.in/api/posts
      fetch('https://api.hsforms.com/submissions/v3/integration/submit/7938875/c2aad326-1e22-453e-97b9-1f0d8aba35df', requestOptions)
          .then(response => response.json())
          .then(data => setError(""))
          .then(data => setResponse("Thanks for subscribing!"))
          .then(data => emptyEmailField());
    }
    else{
      console.warn("Not a valid email address");
      setError("Hmm…this doesn’t look like a valid email address. Try again?");
    }
  };

  function emptyEmailField(){
    let itk = document.getElementById("intheknowemailaddress") as HTMLFormElement;
    itk.value = "";
  }

  function ErrorLabel(){
    if(error !== ""){
      return (
        <label className="error">{ error }</label>
      )
    }
    else if(response !== ""){
      return(
        <label className="red title">{ response }</label>
      )
    }
    return (
      <></>
    );
  }


  return(
    <form onSubmit={ onSubmit }>
      <div className="title">In the loop*</div>
      <input type="email" name="email" id="intheknowemailaddress" placeholder="Enter your email address" onChange={(e) => setEmail(e.target.value)} />
      <ErrorLabel />
      <p className="caption">
        Acorn Analytics Inc. needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.
      </p>
      <button className="button yellow">Subscribe</button>
    </form>
  )
}

function Footer(props: any) {
  return (
    <footer>
      <div className="split">
        <div className="col75">
          <h2 className="h1">Crack your code</h2>
          <a className="button yellow" href="/calendly">Book a consultation</a>
        </div>
        <div className="acorn">
          <img alt="" src={ acorn } />
        </div>
      </div>
      <div className="footerLinks">
        <div className="contact">
          <div className="title">ACORN ANALYTICS</div>
          <div>
            1023 Walnut Street<br />
            Suite 100<br />
            Boulder, CO 80302<br /><br />
            <a className="red" href="mailto:team@acornanalytics.com?subject=Message%20for%20Acorn%20Analytics%20via%20acornanalytics.com">team@acornanalytics.com</a><br /><br />
            <a className="red" href="tel:9253996887">(925) 388-6887</a>

            <div className="twoColumns">
              <a href="https://www.linkedin.com/company/acorn-analytics-inc./" target="_blank" rel="noreferrer"><img alt="LinkedIn" src={ linkedin } /></a>
              <a href="https://open.spotify.com/user/zdjhupypv5lvhi39eu76hfvlv?si=cb775227fdd845f1" target="_blank" rel="noreferrer"><img alt="Spotify" src={ spotify } /></a>
            </div>
          </div>
        </div>
        <div className="links">
          <a href="/" className="red title">HOME</a>
          <a href="/about-us" className="red title">ABOUT US</a>
          <a href="/team" className="red title">THE TEAM</a>
        </div>
        <div className="links">
          <a href="/the-process" className="red title">HOW WE WORK</a>
          <a href="/the-proof" className="red title">CASE STUDIES</a>
          <a href="/careers" className="red title">CAREERS</a>
        </div>
        <EmailForm />
      </div>
      <div className="finale">
        <div className="smalltitle">&copy;2022 Acorn Analytics, Inc.&nbsp;&nbsp;<a className="smalltitle red" href="/privacy">Privacy Policy</a>&nbsp;&nbsp;<a className="smalltitle red" href="/terms-of-use">Terms of Use</a></div>
        <div className="oneftp">
          <div className="smalltitle black">A proud member of</div>
          <img alt="1% For The Planet" src={ oneftp } />
        </div>
      </div>
    </footer>
  );
}


export default Footer;
