import cocktail from "./images/cocktail.jpg";
import DynamicThreeUp from './dynamicthreeup';
import sharpener from './images/team_sharpener.jpg';
import {Helmet} from "react-helmet";
import CareersPromo from "./careerspromo";

function CardSetInCharge() {
    let team = [
      {
        name: "Mike Zawitkowski",
        role: "Managing Partner/CEO",
        img: require("./images/team_mzawitkowski.jpg"),
      },
      {
        name: "Rachel Brown",
        role: "VP, Finance",
        img: require("./images/team_rbrown.jpg"),
      },
      {
        name: "Abby McClelland",
        role: "Principal, Data Solutions",
        img: require("./images/team_amcclelland.jpg"),
      },
      {
        name: "Glenna Patton",
        role: "Principal, CMO",
        img: require("./images/team_gpatton.jpg"),
      },
    ];

    return (
      <div className="cardWrapper">
        {team.map((tm, i) => (
          <TeamCard
            key={i}
            name={tm.name}
            role={tm.role}
            img={tm.img}
          />
        ))}
      </div>
    );
  }

  function CardSetTheCrew() {
    let team = [
      {
        name: "Tarah West",
        role: "Principal, Engineering",
        img: require("./images/team_twest.jpg"),
      },
      {
        name: "Ben Samson",
        role: "Director, HR",
        img: require("./images/team_bsamson.jpg"),
      },
      {
        name: "Jeremy Ruff",
        role: "Project Manager",
        img: require("./images/team_jruff.jpg"),
      },
      {
        name: "Amber Bruce",
        role: "Associate, Business Development",
        img: require("./images/team_abruce.jpg"),
      },
      {
        name: "Giorgio Dal Pra",
        role: "Associate, Data Solutions Architect",
        img: require("./images/team_gdalpra.jpg"),
      },
      {
        name: "Lindsay Sharon",
        role: "Principal, Product",
        img: require("./images/team_lsharon.jpg"),
      },
      {
        name: "Viktor Pishchulin",
        role: "Data Solutions Architect/Dev Ops",
        img: require("./images/team_vpishchulin.jpg"),
      },
      {
        name: "Oscar Vazquez",
        role: "Associate, Engineering",
        img: require("./images/team_ovazquez.jpg"),
      },
      {
        name: "Jacy Wiewiora",
        role: "Associate Digital Marketing Specialist",
        img: require("./images/team_jwiewiora.jpg"),
      },
    ];

    return (
      <div className="cardWrapper">
        {team.map((tm, i) => (
          <TeamCard
            key={i}
            name={tm.name}
            role={tm.role}
            img={tm.img}
          />
        ))}
      </div>
    );
  }

  function CardSetFurries() {
    let team = [
      {
        name: "Izzy Patton",
        role: "Head of Engagement",
        img: require("./images/furry_izzy_patton.jpg"),
      },
      {
        name: "Pepper Brown",
        role: "Project Engagement Lead",
        img: require("./images/furry_pepper_brown.jpg"),
      },
      {
        name: "Kerby Brown",
        role: "Data Security Specialist",
        img: require("./images/furry_kerby_brown.jpg"),
      },
      {
        name: "Snicker Doodle",
        role: "Data Scientist (Trainee)",
        img: require("./images/furry_snicker_scientist.jpg"),
      },
      {
        name: "Kanga Brown",
        role: "Chief Inquiry Officer",
        img: require("./images/furry_kanga_brown.jpg"),
      },
      {
        name: "Stella Sampson",
        role: "Management Trainee (8 weeks)",
        img: require("./images/furry_stella_samson.jpg"),
      },
    ];

    return (
      <div className="cardWrapper">
        {team.map((tm, i) => (
          <TeamCard
            key={i}
            name={tm.name}
            role={tm.role}
            img={tm.img}
          />
        ))}
        <div className="blank_div_for_responsive"></div>
        <div className="blank_div_for_responsive"></div>
      </div>
    );
  }

  function TeamCard(props:any) {
    return (
      <div className="teamCard">
        <img alt={props.name + ", " + props.role}src={props.img.default} />
        <h4>{props.name}</h4>
        <div className="red title">{props.role}</div>
      </div>
    );
  }


function PeepsInCharge(){
  return(
    <section className="borderTopRed">
      <div className="title red">The Peeps in Charge</div>
      <CardSetInCharge />
    </section>
  )
}

function TheCrew(){
  return(
    <section className="borderTopRed">
      <div className="title red">The Crew</div>
      <CardSetTheCrew />
    </section>
  )
}

function TheFurries(){
  return(
    <section className="borderTopRed">
      <div className="title red">OUR FURRY FAMILY</div>
      <CardSetFurries />
    </section>
  )
}

function AnythingBut(){
  return(
    <section>
      <img className="pullLeft spanthreequarter" alt="" src={ sharpener } />
      <h1 className="tightbottom">Anything but dull</h1>
      <h4>Meet team Acorn.<br />We’re a pretty sharp bunch.</h4>
    </section>
  )
}


function Cocktail(props:any){
  return(
    <section id="cocktail" style={ { backgroundImage: `url(${cocktail})` }}>
      <h2 className="h1 red">Humans, just the right cocktail.</h2>
      <h5 className="red half">We’ve been told working with us feels different – that perfect mix of tart and sweet.</h5>
      <ol className="red thirdwidth favNums">
        <li>We explain data in plain speak while never sacrificing the complexity of our field. </li>
        <li>We’re experts in a field full of rookies and we’re relentless about staying ahead of the herd. </li>
        <li>We want to really “get” our clients because we really care about getting results for them. For science nerds, we’re softies that way. </li>
      </ol>
      <p><a href="/about-us" className="button yellow">Learn More</a></p>
    </section>
  )
}



function Team(props: any) {
  return (
    <>
      <Helmet>
        <title>Acorn Analytics // The Team</title>
        <meta name="description" content="Precise data consulting, precisely human. Acorn’s plug-and-play teams of data scientists have full stack capabilities to crack data nuts blocking your business." />
      </Helmet>
      <AnythingBut />
      <PeepsInCharge />
      <TheCrew />
      <TheFurries />
      <Cocktail />
      <CareersPromo />
      <DynamicThreeUp set={ ["ABOUT", "LISTEN", "CONTACT US"] } />
    </>
  );
}


export default Team;
