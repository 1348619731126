function GetInTouch(props:any){
    return(
      <section className="floodBlack tighterbottom">
        <h4>
          Discover <span className="lined">untapped</span> potential under your hood.<br />
          Talk to a relevant data scientist.
        </h4>
        <div className="twoColumns getInTouch">
            <div>
                <p className="caption">Want to find out how we could help your business? Book a free consultation today. <a href="/calendly" className="button yellow">Pencil Us In</a></p>
            </div>
            <div>
                <p className="caption flushRight"><span className="italic">Prefer to keep it casual?</span> <a href="/contact-us" className="button yellow secondary">Get In Touch</a></p>
            </div>
        </div>
      </section>
    )
  }

  export default GetInTouch;