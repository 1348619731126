import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";


function Topnav(props: any) {

  useEffect(() => {
    if(window.pageYOffset > 60){
      document.getElementsByTagName("header")[0].classList.add("opaque");
    }
  });

  function handleClick(){
    document.getElementById("nav")?.classList.toggle("open")
  }

  return (
    <>
      <div className="calendlyCTA"><span className="yellow">Book a free consultation with a data scientist.</span><a href="/calendly" className="yellow button">Pencil Us In</a></div>
      <header className={ props.className }>
        <a href="/"><svg xmlns="http://www.w3.org/2000/svg" width="376.16" height="71.11" viewBox="0 0 376.16 71.11"><path d="M259.51,204.41c0,5,1.29,7,4.85,7.11,2.58.32,4,3.39,3.87,6.14-.32,2.75-2.26,5.33-7.43,5.33-7.43,0-12.44-3.87-13.74-11.63-5.49,7.43-14.7,12.12-27.95,12.12-20.85,0-34.27-12.61-34.27-33.46,0-23.11,15.52-36.52,37.82-36.52,22.63,0,36.85,13.9,36.85,35.88Zm-38-39.92c-14.71,0-24.57,9.37-24.57,24.73,0,15.19,10,23.11,23.28,23.11,14.7,0,25.21-9.7,25.21-24.08C245.38,173.22,235.52,164.49,221.46,164.49Z" transform="translate(-184.84 -153.5)" fill="#e54038"/><path d="M310.83,153.5c10.83,0,20.85,3.88,26,8.08a6.47,6.47,0,0,1,2.1,4.53c0,3.88-4.2,7.11-8.88,4.68-3.88-2.74-8.41-5.82-18.27-5.82C299.36,165,289,174,289,189.38c0,15.51,10.34,23.75,22.78,23.75,6.95,0,12-1,18.27-5.65a5.75,5.75,0,0,1,8.88,4.69,5.87,5.87,0,0,1-2.1,4.36c-7.27,6.79-17.61,8.08-26,8.08-20.84,0-35.71-13.58-35.71-35.23C275.12,167.88,290,153.5,310.83,153.5Z" transform="translate(-184.84 -153.5)" fill="#e54038"/><path d="M387.92,224.61c-22.46,0-37.49-14.55-37.49-35.56s15-35.55,37.49-35.55,37.49,14.55,37.49,35.72C425.41,210.23,410.38,224.61,387.92,224.61Zm0-59.64c-14.06,0-23.6,9.86-23.6,24.25,0,14.22,9.54,23.91,23.6,23.91s23.6-9.69,23.6-23.91C411.52,174.83,402,165,387.92,165Z" transform="translate(-184.84 -153.5)" fill="#e54038"/><path d="M441.41,190.83c0-25.86,13.74-37.33,36.52-37.33,4.53,0,6.31,3.23,6.31,6.46,0,3.4-1.78,6.63-6.31,6.63-15.84,0-23.27,10.67-23.27,24.89v25.21c0,4.2-2.58,6.3-6.62,6.3s-6.63-2.1-6.63-6.3Z" transform="translate(-184.84 -153.5)" fill="#e54038"/><path d="M547.59,187.44c0-15-7.6-22-20-22s-20,6.95-20,22v29.09c0,4.2-3.07,6.46-6.79,6.46s-6.63-2.1-6.63-6.46V187.44c0-23.43,13.42-33.94,33.46-33.94S561,164,561,187.44v29.09c0,4.36-2.91,6.46-6.63,6.46s-6.78-2.1-6.78-6.46Z" transform="translate(-184.84 -153.5)" fill="#e54038"/></svg></a>

        <nav id="nav">
          <button className="toggleButton" id="navbutton" onClick={ handleClick }>
            <span className="active">×</span>
            <span className="inactive"></span>
          </button>
          <ul>
            <li><a href="/the-process" className={ props.route === "the-process" ? "active" : "" }>The Process</a></li>
            <li><a href="/the-proof" className={ props.route === "the-proof" ? "active" : "" }>The Proof</a></li>
            <li><a href="/about-us" className={ props.route === "about-us" ? "active" : "" }>About</a></li>
            <li><a href="/blog" className={ props.route === "blog" ? "active" : "" }>Blog</a></li>
            <li><a href="/contact-us" className={ props.route === "contact-us" ? "active" : "" }>Contact</a></li>
          </ul>
        </nav>
        <Helmet>
          <script src="/navupdate.js"></script>
        </Helmet>
      </header>
    </>
  );
}


export default Topnav;
