
function ThreeUpHuman(props:any){
    return(
      <section className="floodRed">
        <div className="threeColumns lined">
          <div className="column">
            <div className="title">Our humans</div>
            <div className="body">
              We’re science nerds, loud and proud, but don’t really fit the dark room data cruncher mold. We’re really into people and pets. Get to know us!
            </div>
            <div className="action">
              <a href="/" className="button red">Team</a>
            </div>
          </div>
          <div className="column">
            <div className="title">Work with us</div>
            <div className="body">
              Whether you’re a data superhero, super analytical, or simply super natural, you might be the right fit for helping us create juicy stuff.
            </div>
            <div className="action">
              <a href="/careers" className="button red">Careers</a>
            </div>
          </div>
          <div className="column">
            <div className="title">Boogie Down</div>
            <div className="body">
              Get down 'n' nerdy with us. Tune in on Spotify to hear our latest playlists keeping us inspired and boogieing down.
            </div>
            <div className="action">
              <a href="/" className="button red">Listen</a>
            </div>
          </div>
        </div>
      </section>
    )
  }

  export default ThreeUpHuman;