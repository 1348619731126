import { useState, useEffect } from 'react';
import DynamicThreeUp from './dynamicthreeup';
import {Helmet} from "react-helmet";

function BlogHeader(props:any){
    return(
        <section className="blogHeader">
            <div className="blogHeaderWrapper">
                <h1>Blog</h1>
                <h6>Fresh cuts on data from real scientists, without the tech talk.<br />From business, to health, to ethics and privacy, we get into it. Big time. </h6>
            </div>
        </section>
    )
}

function BlogArticlesList(props:any){
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://api.gregjohnson.info/blogpull.php?page=${page}`,
      {
        method: "GET"
      }
    )
      .then(res => res.json())
      .then(response => {
        console.info(response.objects);
        setItems(response.objects);
        setIsLoading(false);
      })
      .catch(error => console.log(error));
  }, [page]);

    return(
        <section className="blogTop">
            <div className="blogChapterOne">
                <div className="blogHeroes">
                
                {isLoading && <p>Fetching…</p>}
                
                { items.length && items.slice(0, 2).map((article:any, i:number) =>
                    <a href={ "/blog/" + article.id} className="article" key={i}>
                      <div className="articleImage" style={ {backgroundImage: `url(${article.featured_image})`} }></div>
                      <div className="caption padtop_small red articleDateTags">{ new Date(article.publish_date).toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) } </div>
                      <h2 className="articleTitle">{ article.title }</h2>
                    </a>
                )}
                </div>
                <BlogSidebar />
            </div>
            <div className="blogChapterTwo">
                { items.length && items.slice(2, items.length).map((article:any, i:number) =>
                    <a href={ "/blog/" + article.id} className="article" key={i}>
                      <div className="articleImage" style={ {backgroundImage: `url(${article.featured_image})`} }></div>
                      <div className="caption padtop_small red articleDateTags">{ new Date(article.publish_date).toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) } </div>
                      <h2 className="articleTitle">{ article.title }</h2>
                    </a>
                )}
            </div>
        </section>
    )
}

function BlogSidebar(props:any){
    return(
        <div className="blogSidebar">
            <div className="block">
                <div className="title red">CURRENTLY SPINNING</div>
                <iframe title="Spotify Playlist" src="https://open.spotify.com/embed/playlist/1pwkQG4Q4zGw7M4TP0Ethi?utm_source=generator" width="100%" height="380" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </div>
            <div className="block">
                <div className="title red">JUST SAYIN’</div>
                <h4 className="quote">The real voyage of discovery consists not in seeking new landscapes, but in seeing with new eyes.</h4>
                <div className="title red">- MARCEL PROUST</div>
            </div>
            <div className="block">
                <div className="title red">RANDOM FACT #299</div>
                <p className="red">The tallest stack of pancakes was</p>
                <h2 className="inline">101.8</h2>
                <p className="red">cm (3ft, 4in) and was made by Center Parcs Sherwood Forest (UK), in Rufford, Newark, UK on 8 February 2016.</p>
                <div className="title red">- Guinness World Records</div>
            </div>
        </div>
    )
}



  



function TheBlog(props: any) {
  return(
    <>
      <Helmet>
        <title>Acorn Analytics // Blog</title>
        <meta name="description" content="Dive into data science trends affecting business and life. Whether you’re a CEO, Marketer, or Customer Success leader, you’ll find cool insights for your world." />
      </Helmet>
      <BlogHeader />
      <BlogArticlesList />
      <DynamicThreeUp set={ ["THE PROOF", "THE PROCESS", "ABOUT"] } />
    </>
  )
}


export default TheBlog;
