function BookAConsultation(props:any){
    return(
      <section className="floodBlack tighterbottom">
        <h2 className="tighttop">
          Book a consultation
        </h2>
        <div className=" getInTouch">
            <div>
                <h6 className="tightbottom"><span className="padright">Got a data hitch? Talk to a relevant data scientist.</span> <a href="/calendly" className="yellow button">Schedule it</a>&nbsp;&nbsp;<span className="italic">It’s on the house.</span></h6>
            </div>
        </div>
      </section>
    )
  }

  export default BookAConsultation;