


function TermsHeader(props:any){
    return(
        <section className="centered floodRed">
            <h1>Terms of Use</h1>
            <div className="title">Effective:<br />September 8, 2018</div>
        </section>
    )
}


function TermsDetails(props:any){
    return(
        <section className="longform">
            <h4 className="padtop_medium">Definitions</h4>
            <p>These Terms of Use (these “Terms”) are a legal agreement (“Agreement”) between you (“User”) and Acorn Analytics Inc. (“Acorn,” “we,” “Company”, or “our”) providing, among other things, the terms and conditions of your use of our website at www.acornanalytics.com (the “Website” or “Site”), downloadable software that Acorn makes available to you at the Website (the “Software”), and all proprietary services, data, and materials accessed via the Website or Software (the “Service” or “Services”). The Website, Software, and Services are sometimes individually referred to in these Terms as a “Property” or collectively as the “Properties.”</p>

            <p>If you’re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that organization or entity’s behalf and bind them to these Terms (in which case, the references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or entity). You represent and warrant that you are of legal age to form a binding contract (or if not, you’ve received your parent’s or guardian’s permission to use the Services and gotten your parent or guardian to agree to these Terms on your behalf).</p>

            <p>By browsing, accessing, or using any Property, you agree to these Terms in their entirety, without modification. If you do not agree to these Terms, you are not authorized to use the Properties.</p>

            <h4>Restrictions</h4>
            <p>Users are prohibited from doing the following:</p>

            <ul>
            <li>leasing, selling, copying, sublicensing, transferring, or assigning any information, intellectual property, goods, or services provided on the Site</li>
            <li>using the Site for any illegal purpose</li>
            <li>gaining unauthorized access to the Company’s data or the data of other Users</li>
            <li>altering, modifying, adapting, reverse engineering, decompiling, disassembling, or hacking the Company’s intellectual property</li>
            <li>altering or modifying another Website to falsely imply that it is associated with the Company’s website,</li>
            <li>using or exporting the Company’s information, products, or services in violation of U.S. export laws and regulations</li>
            <li>violating anyone else’s legal rights (for example, privacy rights) or any laws (for example, copyright laws) in the User’s jurisdiction</li>
            <li>using the Site or the Company’s Services to transmit content that could be deemed unlawful, threatening, harassing, racist, abusive, libelous, pornographic, vulgar, defamatory, obscene, indecent, or otherwise inappropriate, including any messages constituting or encouraging criminal conduct</li>
            <li>breaching, or attempting to breach, the Website’s security systems</li>
            <li>enabling third parties to violate the Terms of Use, and</li>
            <li>failing to ensure that all Users of the site are at least 13 years of age or older.</li>
            </ul>

            <h4>Links To Other Websites and Third-Parties</h4>
            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Acorn.</p>

            <p>Acorn has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Acorn Analytics Inc. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

            <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>

            <h4>Governing Law and Arbitration</h4>
            <p>These Terms shall be governed and construed in accordance with the laws of the State of California.</p>

            <p>All claims and disputes arising under or relating to this Agreement are to be settled by binding arbitration in the state of California or another location mutually agreeable to the parties. An award of arbitration may be confirmed in a court of competent jurisdiction.</p>

            <h4>Entire Agreement</h4>
            <p>These Terms constitute the entire agreement between us and supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and Acorn with respect to the Service.</p>

            <h4>DMCA, Copyright and Intellectual Property</h4>
            <p>The Service and its original content, features and functionality are and will remain the exclusive property of Acorn and its licensors.</p>

            <p>Acorn will respond to clear notices of copyright infringement consistent with the Digital Millennium Copyright Act, 17 USC 512(c)(2) (“DMCA”) and its response to such notices may include removing or disabling access to the allegedly infringing content, terminating the accounts of repeat infringers, and making good-faith attempts to contact the user who posted the content at issue so that he or she may, where appropriate, make a counter-notification.</p>

            <p>Acorn’s Copyright Agent can be reached by mail at:</p>

            <p>Acorn Analytics Inc.</p>

            <p>Attn: Copyright Agent</p>

            <p>1261 Locust Street</p>

            <p>Suite 3</p>

            <p>Walnut Creek, CA 94596</p>

            <h4>Term, Termination, and Survival</h4>
            <p>This Agreement shall be effective as of the date (the “Effective Date”) the User accepts the terms herein or first accesses, downloads or uses any of the services or information (collectively, the “Services”) on this Website or other Properties and shall remain in effect for so long as the User uses or accesses any of the Services (the “Term”).</p>

            <p>Upon termination of the Term, the User shall no longer be permitted to use or access the Services.</p>

            <p>The terms herein that contemplate obligations after the Term, including but not limited to Indemnification and Limitation of Liability, shall survive termination.</p>

            <h4>Severability</h4>
            <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</p>

            <p>If any provision of these Terms or the Privacy Policy is held to be invalid or unenforceable in whole or in part by a court of competent jurisdiction, the remaining provisions of these Terms and Privacy Policy will remain in effect.</p>

            <h4>Indemnification and Limitation of Liability</h4>
            <p>You will indemnify and hold Acorn, its directors, officers, employees, affiliates, agents, contractors, and suppliers harmless from any damages, liabilities, or expenses arising from or based upon your failure to comply with these Terms. Acorn may, at your expense, assume the exclusive defense and control of any matter for which you are required to indemnify Acorn, and you agree to cooperate with Acorn’s defense of these claims. Acorn will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it. This Section will survive any termination of your Service accounts or right to use any other Property.</p>

            <h4>Warranty</h4>
            <p>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>

            <h4>Privacy Policy</h4>
            <p>You acknowledge that you have read and understand the Privacy Policy found at <a href="https://web.archive.org/web/20200922173704/http://www.acornanalytics.com/privacy-policy">www.acornanalytics.com/privacy-policy</a>. Our Privacy Policy is hereby incorporated into these Terms.</p>

            <h4>Amendments</h4>
            <p>We may from time to time modify or replace these Terms and our Privacy Policy.</p>

            <p>If a revision is material we will try to provide at least 15 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

            <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>

            <h4>Assignment</h4>
            <p>You must not transfer any of your rights or obligations under these Terms to anyone else without our prior written consent. All of our rights and obligations under these Terms are assignable.</p>

            <h4>Notices</h4>
            <p>We may notify you via postings on a Website or via email or any other means to the contact address you provide to us. All notices given by you or required from you under these Terms will be in writing and addressed to: Acorn Analytics Inc. 1261 Locust Street Suite 3, Walnut Creek, CA 94596, Attn: Legal Department. Any notices that you provide not in compliance with this Section will have no legal effect.</p>
        </section>
    )
}
  



function Terms(props: any) {
  return(
    <>
      <TermsHeader />
      <TermsDetails />
    </>
  )
}


export default Terms;
