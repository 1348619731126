import watering from "./images/proof-watering-can.jpg";
import DynamicThreeUp from "./dynamicthreeup";
import GetInTouch from "./getintouch";
import Discover from "./discover";
import TheProofSection from "./theproofsection";
import {Helmet} from "react-helmet";


function Hero(props:any){
    return(
      <section className="twoColumns noPadLeft imageBackgroundLeft">
        <div className="column"></div>
        <div className="column">
            <h1 className="tightbottom">Growth,<br />hacked.</h1>
            <h4>The proof is in our clients’ success</h4>
            <p className="threequarterwidth red">Feeding our clients data solutions to grow their businesses is our bottom line. Unleashing innovation along the way is our superpower. Find out how we’ve delivered for other companies and let us know how we can help yours blossom.</p>
        </div>
        <div className="imgWrapper"><img src={ watering } alt="" /></div>
      </section>
    )
  }


  function TheProofPage(props:any){
      return(
        <>
        <Helmet>
          <title>Acorn Analytics // The Proof</title>
          <meta name="description" content="See how Acorn’s data science delivers for clients. From predictive analytics to machine learning and other forms of Artificial Intelligence, the proof is here." />
        </Helmet>
        <Hero />
        <TheProofSection />
        <GetInTouch />
        <Discover />
        <DynamicThreeUp set={ ["LISTEN", "CONTACT US", "BLOG"] } />
      </>
      )
  }

  export default TheProofPage;