import dog from "./images/dog.jpg";

function Friendly(props:any){
    return(
      <section id="friendly" style={ { backgroundImage: `url(${dog})` }}>
        <h1 className="red h1">We’re friendly</h1>
        <h4 className="red">Get a closer look at our team of cuddly crack scientists.</h4>
        <p className="red twoxleading">Our people are our are business. See why they’re a special breed – not only their crack skills but also as humans. And our cuddly co-workers? They’re the best!</p>
        <p><a href="/team" className="button yellow">The Team</a></p>
      </section>
    )
  }
  
  export default Friendly;