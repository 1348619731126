import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ThreeUpHuman from './threeuphuman';


function BlogArticle(props:any){
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [article, setArticle] = useState<any>({});

  type BlogParams = {
    blogid: string;
  };
  
  const { blogid } = useParams<BlogParams>();

  
  
  function processItems(props:any){
    for(let x=0; x< props.length; x++){
      if(props[x].id == blogid){
        let articleArray = [];
        articleArray.push(props[x]);
        let cleanedPostBody = props[x].post_body.replace(/style=/g, 'origStyle=');
        cleanedPostBody = cleanedPostBody.replace(/&nbsp;/g, ' ');
        props[x].post_body = cleanedPostBody;
        setItems(articleArray);
        setArticle(props[x]);
      }
      else{
        console.warn(blogid);
        console.info(props[x]);
      }
    }
  }


  useEffect(() => {
    fetch(
      `https://api.gregjohnson.info/blogpull.php?page=${page}`,
      {
        method: "GET"
      }
    )
      .then(res => res.json())
      .then(response => {
        processItems(response.objects);
        setIsLoading(false);
      })
      .catch(error => console.log(error));
  }, [page]);

    return(
        <>
        <section className="blogDetail floodRed">
            <div className="blogDetailWrapper">
                <caption className="white caption">{ new Date(article.publish_date).toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }</caption>
                <h1 className="centered">{ article.title }</h1>
            </div>
        </section>
        <div className="blogDetailBody" dangerouslySetInnerHTML={{__html: article.post_body }}></div>
        
        </>
    )
}




  



function BlogDetail(props: any) {
  return(
    <>
      <BlogArticle />
      <ThreeUpHuman />
    </>
  )
}


export default BlogDetail;
