import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './home';
import Team from './team';
import Process from "./process";
import Topnav from './navbar';
import Footer from './footer';
import TheProofPage from "./theproof";
import AboutUs from "./aboutus";
import ContactUs from "./contactus";
import TheBlog from "./blog";
import BlogDetail from "./blogdetail";
import Careers from "./careers";
import Privacy from "./privacy";
import Terms from "./terms";
import Calendly from "./calendly";
import CaseDetail from "./casedetail";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


ReactDOM.render(
    <React.StrictMode>
      <Router>
        <Switch>
          <Route exact path="/team">
            <Topnav route="team" />
            <Team />
            <Footer />
          </Route>
          <Route exact path="/about-us">
            <Topnav route="about-us" />
            <AboutUs />
            <Footer />
          </Route>
          <Route exact path="/about-us">
            <Topnav route="about-us" />
            <AboutUs />
            <Footer />
          </Route>
          <Route exact path="/contact-us">
            <Topnav route="contact-us" />
            <ContactUs />
            <Footer />
          </Route>
          <Route exact path="/the-proof">
            <Topnav route="the-proof" />
            <TheProofPage />
            <Footer />
          </Route>
          <Route exact path="/the-process">
            <Topnav route="the-process" />
            <Process />
            <Footer />
          </Route>
          <Route exact path="/careers">
            <Topnav route="about" />
            <Careers />
            <Footer />
          </Route>
          <Route exact path="/blog">
            <Topnav route="blog" />
            <TheBlog />
            <Footer />
          </Route>
          <Route exact path="/blog/:blogid">
            <Topnav route="blog" className="floodRed" />
            <BlogDetail />
            <Footer />
          </Route>
          <Route exact path="/case/:casename">
            <Topnav />
            <CaseDetail />
            <Footer />
          </Route>
          <Route exact path="/privacy">
            <Topnav className="floodRed" />
            <Privacy />
            <Footer />
          </Route>
          <Route exact path="/terms-of-use">
            <Topnav className="floodRed" />
            <Terms />
            <Footer />
          </Route>
          <Route exact path="/calendly">
            <Topnav />
            <Calendly />
            <Footer />
          </Route>
          <Route exact path="/">
            <Topnav route="home" />
            <Home />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
