import willow from './images/8-1-21Willow-2.jpeg'
import migration from './images/8-1-21Splunk-Migration-1.jpeg';
import casepreview_machinelearning from "./images/casepreview_machinelearning.jpg";
import casepreview_predictchurn from "./images/casepreview_predictchurn.jpg";
import casepreview_cloudmigration from "./images/casepreview_cloudmigration.jpg";
import casepreview_producttesting from "./images/casepreview_producttesting.jpg";

function TheProofSection(props:any){
    return(
      <section className="floodYellow">
        <div className="twoColumns borderTop">
          <div className="column"><h2 className="h1">The Proof</h2></div>
          <div className="column">
            <p>Growth hacked, human backed. See how our team got down 'n' dirty with the data to create serious business results for clients.</p>
          </div>
        </div>
        <div className="twoColumns">
          <a className="column casePreview" href="/case/product-testing">
            <h2>Product testing platform reimagines customer support</h2>
            <h6><span className="title bordered">Industry</span>Technology<span className="title bordered marginleft">Function</span>Customer Success</h6>
            <img className="caseStudyHero" alt="Product Testing Case Study" src={ casepreview_producttesting } />
          </a>
          <a className="column casePreview" href="/case/predict-churn">
            <h2>Designing a model to predict churn</h2>
            <h6><span className="title bordered">Industry</span>Technology<span className="title bordered marginleft">Function</span>Customer Success</h6>
            <img className="caseStudyHero" alt="Sales Forecasting Case Study" src={ casepreview_predictchurn } />
          </a>
        </div>
        <div className="twoColumns">
          <a className="column casePreview" href="/case/sales-forecasting">
            <h2>Is sales forecasting machine learning-ready...or not?</h2>
            <h6><span className="title bordered">Industry</span>Technology<span className="title bordered marginleft">Function</span>Sales</h6>
            <img className="caseStudyHero" alt="Machine Learning Case Study" src={ casepreview_machinelearning } />
          </a>
          <a className="column casePreview" href="/case/cloud-migration">
            <h2>Turning cloud migration pains into customer gains</h2>
            <h6><span className="title bordered">Industry</span>Technology<span className="title bordered marginleft">Function</span>Customer Success</h6>
            <img className="caseStudyHero" alt="Cloud Migration Case Study" src={ casepreview_cloudmigration } />
          </a>
        </div>
      </section>
    )
  }

  export default TheProofSection;